import React, { useEffect, useRef } from "react";
import { FacebookShareButton } from "react-share";
import Button from "../../reusable/Button";
import "./index.style.css";

const Offers = () => {
  const offersLinkRef = useRef<HTMLAnchorElement | null>(null);
  const hash = window.location.hash;
  // const mailtoLink = "mailto:esthetiquebasilix@gmail.com";

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hash === "#offers" && offersLinkRef.current) {
        if (offersLinkRef.current) {
          offersLinkRef.current.click();
        }
      }
    }, 2000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  const currentPage = "https://esthetiquebasilix.be/#offers";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentPage).then(() => {
      alert("Lien copié dans le presse-papiers !");
    });
  };

  return (
    <section id="offers" className="offers">
      <a ref={offersLinkRef} href="#offres" style={{ display: "none" }}>
        Go to Offres
      </a>
      <div className="offres-block">
        <h1>NOS OFFRES</h1>
        {/* pentru oferte noi pe viitor */}
        {/* <section className="offer-section offer-section2">
          <div className="offer-container">
            <div className="offer-text">
              <p>
                En janvier 2025, nous vous offrons l'occasion de chouchouter
                votre peau avec un traitement Hollywood Peeling à un prix
                spécial ! Ce soin transformera votre peau, la rendant lisse,
                éclatante et revitalisée. Profitez de 20 % de réduction et
                commencez l'année avec un teint radieux. Offrez une belle
                apparence à 2025 !
              </p>
            </div>
            <div className="offer-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/1000022006.jpg?alt=media&token=93a63cd8-8109-4502-bd2d-f832c5b4517f"
                alt="Imagine Oferta"
              />
            </div>
          </div>
        </section> */}
        <div className="offers-container">
          <div className="offers-all-text">
            <p>
              Prenez rendez-vous en ligne et bénéficiez de 5 % de réduction sur
              toutes les procédures au Centre Esthétique Basilix.
            </p>
            <Button title="Prendre rendez-vous" />
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/promo1.png?alt=media&token=eeccb859-2d00-40ac-80b1-304e38d6bd89"
            alt="..."
          />
        </div>
        {/* <section id="offres" className="offers-container-seconde">
          <img
            className="offres-img-seconde"
            src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/2024-08-30-09-11-45-200.jpg?alt=media&token=f5d81480-bfda-4dbd-864d-fbeffd4fc01d"
            alt="promo"
          />
        </section> */}
        <span className="share-title"> Partager sur:</span> <br />
        <div className="share-container">
          <FacebookShareButton url={currentPage}>
            <div className="share-facebook">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="blue"
                className="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"></path>
              </svg>
            </div>
          </FacebookShareButton>
          <div onClick={copyToClipboard} className="copy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="rgba(0, 0, 0, 0.5)"
              className="bi bi-copy"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
              />
            </svg>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#97afbc"
          fill-opacity="1"
          d="M0,64L80,101.3C160,139,320,213,480,224C640,235,800,181,960,170.7C1120,160,1280,192,1360,208L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Offers;
